import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogTitle,
    SpecialAlertDialogFooter,
} from '@/components/AlertDialog'
import { Button } from '../Button'
import mapIcon from '@/assets/private/map.svg'
import { useState } from 'react'

interface GeolocationWarningModalProps {
    open: boolean
    setOpen: (open: boolean) => void
}

const GeolocationWarningModal: React.FC<GeolocationWarningModalProps> = ({ open, setOpen }) => {
    const handleClose = () => {
        setOpen(false)
        localStorage.setItem('continueWithoutGeo', 'true')
    }

    const [isDesktopExpanded, setIsDesktopExpanded] = useState(false)
    const [isMobileExpanded, setIsMobileExpanded] = useState(false)

    return (
        <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogContent className='bg-white min-h-[320px] w-full max-w-[577px] rounded-[15px] p-4 md:p-0'>
                <div className='flex flex-col items-center text-center'>
                    <img src={mapIcon} className='w-[50px] h-[50px] md:w-[60px] md:h-[60px] mt-6' />

                    <AlertDialogTitle className='font-semibold text-lg md:text-2xl text-zentive-green-dark mt-4'>
                        Location Access Required
                    </AlertDialogTitle>

                    <AlertDialogDescription className='text-sm md:text-md text-black-500 mt-4 px-4'>
                        To provide an accurate time format and map location, Zentive requires access
                        to your location.
                        <div className='mt-4 space-y-3 text-left text-sm md:text-base px-4'>
                            <div>
                                <h4
                                    className='font-medium cursor-pointer flex items-center'
                                    onClick={() => setIsDesktopExpanded(!isDesktopExpanded)}
                                >
                                    For Business Owners: {isDesktopExpanded ? '▼' : '▶'}
                                </h4>
                                {isDesktopExpanded && (
                                    <ol type='1' className='list-decimal pl-8'>
                                        <li>Go to Settings</li>
                                        <li>Search for your browser settings</li>
                                        <li>Click Location and Allow</li>
                                    </ol>
                                )}
                            </div>

                            <div>
                                <h4
                                    className='font-medium cursor-pointer flex items-center'
                                    onClick={() => setIsMobileExpanded(!isMobileExpanded)}
                                >
                                    For Crew: {isMobileExpanded ? '▼' : '▶'}
                                </h4>
                                {isMobileExpanded && (
                                    <ol type='1' className='list-decimal pl-8'>
                                        <li>Go to Settings</li>
                                        <li>Click Privacy and then Location</li>
                                        <li>Click Allow</li>
                                    </ol>
                                )}
                            </div>
                        </div>
                    </AlertDialogDescription>
                </div>

                <SpecialAlertDialogFooter className='mt-6 flex flex-col w-full bg-gray-100 rounded-b-[5px] p-4'>
                    <Button onClick={handleClose} className='h-12 w-full font-semibold text-base'>
                        Okay, Thanks!
                    </Button>
                </SpecialAlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default GeolocationWarningModal
