import { Button } from '@/components/Button'
import { Card, CardFooter } from '@/components/Card'
import { Form } from '@/components/Forms'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from '@/hooks/useToast'
import { useNavigate, useParams } from 'react-router-dom'
import BreadCrumbs from '@/components/BreadCrumbs'
import { getNextBusinessInvoiceNumber, saveInvoice } from '@/api/invoicing'
import { InvoiceType, invoiceSchema } from '@/api/invoicing/schema'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { customerInvoiceAtom, invoiceContentAtom, invoiceTaxAtom } from '@/store/owner'
import {
    BREADCRUMBS_PADDING_STYLE,
    DEFAULT_QUOTE_TAX_VALUE,
    DEFAULT_QUOTE_VALUE,
    RECORD_PREFIX,
} from '@/constants'
import { startTransition, useEffect } from 'react'
import {
    jobGenerateInvoiceAtom,
    // jobInvoiceDiscountAtom
} from '@/store/job'
import { getProfileWithQuoteByJobId } from '@/api/profile'
import { businessIdAtom, isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
// import { calculateGrossIncome, calculateNetIncome } from '@/utils/calc'
import AddInvoiceFromJobHeader from './CardHeader'
import AddInvoiceFromJobContent from './CardContent'
import { formatRecordNumber } from '@/utils/helper'

const AddInvoiceFromJob = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const setCustomer = useSetAtom(customerInvoiceAtom)
    const setInvoiceContent = useSetAtom(invoiceContentAtom)
    const setGenerateInvoiceData = useSetAtom(jobGenerateInvoiceAtom)
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    // const jobInvoiceDiscount = useAtomValue(jobInvoiceDiscountAtom)
    const [user] = useAtom(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const businessId = useAtomValue(businessIdAtom)

    const { data: nextInvoiceNumber, isSuccess: isInvoiceNumberSuccess } = useQuery({
        queryKey: ['nextInvNumber'],
        queryFn: () => getNextBusinessInvoiceNumber(businessId),
    })

    const { data: quote, isSuccess } = useQuery({
        enabled: !!id,
        queryKey: ['profileWithQuote', id],
        queryFn: () => getProfileWithQuoteByJobId(id as string),
    })

    const [taxes, setTaxes] = useAtom(invoiceTaxAtom)

    const methods = useForm<InvoiceType>({
        mode: 'onSubmit',
        resolver: async (data, context, options) => {
            const result = await zodResolver(invoiceSchema)(data, context, options)
            return result
        },
        defaultValues: {
            sharedNotes: '',
        },
    })

    const { mutate: saveInvoiceMu, isPending } = useMutation<ResponseType, AxiosError, InvoiceType>(
        {
            mutationFn: (data) => saveInvoice(data),
            onSuccess: () => {
                toast({
                    description: 'Invoice sent!',
                    variant: 'default',
                })
                setCustomer(null)
                setInvoiceContent(DEFAULT_QUOTE_VALUE)
                setTaxes(DEFAULT_QUOTE_TAX_VALUE)
                setGenerateInvoiceData(null)
                navigate('/financial-management/invoicing')
            },
        },
    )

    const {
        handleSubmit,
        reset,
        watch,
        formState: { isValid },
        setValue,
    } = methods

    const watchedFields = watch()

    const onSubmit = (data: InvoiceType) => {
        if ((watchedFields?.totalAmount ?? 0) < 0.5) {
            toast({
                title: 'Error! Invalid Amount!',
                description: 'Total Amount must be equal or higher than $0.5',
                variant: 'destructive',
            })
            return
        }

        // console.log(data)

        saveInvoiceMu(data)
    }

    useEffect(() => {
        if (quote && isSuccess)
            reset({
                ...quote,
                businessId: user?.businessId,
                profile: {
                    ...quote?.profile,
                    address: quote?.address,
                },
                expense: quote?.expense,
                internalNotes: '',
            })

        setValue('businessInvoiceNumber', nextInvoiceNumber as number)
    }, [quote, isSuccess, nextInvoiceNumber])

    useEffect(() => {
        const totalExpense =
            quote?.expense
                ?.filter((ex) => !ex.isNotIncludedInInvoice)
                ?.reduce((sum, ex) => sum + ex.total, 0) || 0

        const totalProducts =
            quote?.productServiceGroup
                .filter((item) => item.type === 'product')
                .reduce((sum, item) => sum + item.cost, 0) || 0

        const totalServices =
            quote?.productServiceGroup
                .filter((item) => item.type === 'service')
                .reduce((sum, item) => sum + item.cost, 0) || 0

        const subTotal = totalExpense + totalProducts + totalServices

        const discount =
            (quote?.isDiscountPercent
                ? (subTotal * (quote?.discount ?? 0)) / 100
                : quote?.discount) ?? 0

        const discountedSubtotal = subTotal

        setInvoiceContent({
            totalUnitPrice: quote?.productServiceGroup
                ? quote?.productServiceGroup
                      ?.map((datum) => datum.unitPrice!)
                      .reduce((a, b) => a + b)
                : 0.0,
            totalProductsAndServices: quote?.productServiceGroup
                ? quote?.productServiceGroup
                      ?.map((datum) => datum.unitPrice! * datum.quantity!)
                      .reduce((a, b) => a + b)
                : 0.0,
            totalServicePrice: totalServices,
            totalProduct: totalProducts,
            totalService: totalServices,
            totalExpense: totalExpense,
            laborCost: 0,
            subTotal: discountedSubtotal,
            discount: discount ?? 0,
            internalNotes: '',
        })
    }, [quote])

    useEffect(() => {
        startTransition(() => {
            setValue('taxName', taxes.name)
            setValue('productTax', taxes.productTax)
            setValue('serviceTax', taxes.serviceTax)
            setValue('expenseTax', taxes.expenseTax)
            setValue('jobID', id)
        })
    }, [taxes])

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs
                titleName='Add Invoice'
                pathId=''
                label={RECORD_PREFIX.invoice + formatRecordNumber(nextInvoiceNumber as number)}
            />
            <div className='mt-4 w-full'>
                <Form {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Card className='border-0 border-t-8 border-zentive-green-dark '>
                            {isSuccess && quote && isInvoiceNumberSuccess && (
                                <AddInvoiceFromJobHeader invoiceNumber={nextInvoiceNumber} />
                            )}

                            {isSuccess && quote && <AddInvoiceFromJobContent />}

                            <CardFooter className='lg:flex lg:justify-end rounded-b-md bg-gray-200 '>
                                <div className='flex space-x-4 mt-6'>
                                    <Button
                                        type='button'
                                        variant={'outline'}
                                        className='w-full'
                                        onClick={() => {
                                            navigate('/financial-management/invoicing')
                                            setInvoiceContent(DEFAULT_QUOTE_VALUE)
                                            setTaxes(DEFAULT_QUOTE_TAX_VALUE)
                                            setGenerateInvoiceData(null)
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type='submit'
                                        className='w-full disabled:bg-zentive-gray-medium'
                                        disabled={!isValid || isPending}
                                    >
                                        Save and Send Invoice
                                    </Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </form>
                </Form>
            </div>
        </div>
    )
}

export default AddInvoiceFromJob
