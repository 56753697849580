import { CardTitle } from '@/components/Card'
import { TableRow, TableCell, Table, TableBody } from '@/components/Table'
import ExpenseTableHead from './ExpenseTable/ExpenseHeader'
import { QuoteType } from '@/api/quoting/schema'
import { useLocation } from 'react-router-dom'

const Expense = ({ quote }: { quote: QuoteType }) => {
    const { pathname } = useLocation()

    const filteredExpenses = pathname.startsWith('/financial-management/invoicing/add-invoice')
        ? quote?.expense?.filter((exp) => !exp.isNotIncludedInInvoice)
        : quote?.expense?.filter((exp) => !exp.isSavedFromCrew)

    if (!filteredExpenses?.length) {
        return null
    }

    return (
        <div className='border border-[#EBEBEB] p-6 my-8 rounded-md'>
            <div className='flex flex-row justify-between w-full mb-6'>
                <CardTitle className='text-lg font-semibold'>Expenses</CardTitle>
            </div>
            <Table className='table-auto whitespace-normal mx-auto'>
                <ExpenseTableHead />
                <TableBody className='bg-white'>
                    {filteredExpenses?.map((data, index) => (
                        <TableRow key={index} className='whitespace-nowrap'>
                            <TableCell className=' text-zentive-black text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                {data.accountName}
                            </TableCell>
                            <TableCell className=' text-zentive-black text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                {data.description}
                            </TableCell>
                            <TableCell className=' text-zentive-black text-base max-w-[200px] text-ellipsis overflow-hidden'>
                                {data.date}
                            </TableCell>
                            <TableCell className=' text-zentive-black text-base text-right max-w-[200px] text-ellipsis overflow-hidden'>
                                ${data.total}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

export default Expense
