import { getInvoiceReport } from '@/api/reports'
import {
    InvoiceReportFilterType,
    InvoiceReportRecordType,
    InvoiceReportType,
} from '@/api/reports/schema'
import { Button } from '@/components/Button'
import { Card, CardContent } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import { Pagination } from '@/components/Pagination'
import { PaginationType } from '@/components/Pagination/schema'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import {
    INVOICE_LABEL_COLOR,
    INVOICE_STATUS,
    INVOICE_STATUS_LABEL,
    RECORD_PREFIX,
    US_FORMAT,
} from '@/constants'
import { isViewedAsAtom, timezoneAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { invoiceReportExportAtom, invoiceReportFilterAtom } from '@/store/reports'
import { cn, displayCurrency, formatRecordNumber } from '@/utils/helper'
import { useQuery } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { InvoiceReportOverView } from './InvoiceReportOverview'
import { formatToUTCWithOffset } from '@/utils/time'

const tableHeader = [
    'Invoice Number',
    'Customer Name',
    'Email Address',
    'Invoice Amount',
    'Date',
    'Status',
    'Contact Number',
    'Billing Address',
    // 'Processed By',
]

const InvoiceReportTable = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const tz = useAtomValue(timezoneAtom)

    const invoiceReportFilter = useAtomValue(invoiceReportFilterAtom)
    const [exportAtom, setExportAtom] = useAtom(invoiceReportExportAtom)

    const [isAllChecked, setIsAllChecked] = useState(false)

    useEffect(() => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 1,
        }))
    }, [invoiceReportFilter])

    const [pagination, setPagination] = useState<PaginationType>({
        page: 1,
        pageSize: 5,
    })

    const { data: report } = useQuery<InvoiceReportType>({
        queryKey: ['invoiceReport', user?.businessId, invoiceReportFilter, pagination],
        queryFn: () =>
            getInvoiceReport(
                user?.businessId as string,
                invoiceReportFilter as InvoiceReportFilterType,
                pagination,
            ),
    })

    const handleCheckAllChange = (isChecked: boolean) => {
        setIsAllChecked(isChecked)

        if (isChecked) {
            // Add all available records to exportAtom
            const allRecords = report?.content?.report ?? []

            setExportAtom({
                records: allRecords,
                unpaid: report?.content?.unpaid ?? 0,
                totalUnpaid: report?.content?.totalUnpaid ?? 0,
                totalPastDue: report?.content?.totalPastDue ?? 0,
                pastDue: report?.content?.pastDue ?? 0,
                totalRecords: report?.content?.totalRecords ?? 0,
                totalAmount: report?.content?.totalAmount ?? 0,
            })
        } else {
            // Clear all records from exportAtom
            setExportAtom({
                records: [],
                unpaid: 0,
                totalUnpaid: 0,
                totalPastDue: 0,
                pastDue: 0,
                totalRecords: 0,
                totalAmount: 0,
            })
        }
    }

    const handleCheckChange = (invoice: InvoiceReportRecordType) => {
        setExportAtom((prev) => {
            if (!prev) {
                // If there is no existing filter, create one with the new invoiceId
                return {
                    records: [invoice],
                    unpaid: invoice.status === INVOICE_STATUS.OPEN ? 1 : 0,
                    totalUnpaid: invoice.status === INVOICE_STATUS.OPEN ? invoice.invoiceAmount : 0,
                    totalPastDue: invoice.status === INVOICE_STATUS.PAST_DUE ? 1 : 0,
                    pastDue: invoice.status === INVOICE_STATUS.PAST_DUE ? invoice.invoiceAmount : 0,
                    totalRecords: 1,
                    totalAmount: invoice.invoiceAmount,
                }
            }

            const isInvoiceSelected = prev.records?.some(
                (record) => record.invoiceId === invoice.invoiceId,
            )

            // Update records based on whether the job is being added or removed
            const updatedJobs = isInvoiceSelected
                ? prev.records.filter((record) => record.invoiceId !== invoice.invoiceId) // Remove if exists
                : [...(prev.records || []), invoice] // Add if it doesn't exist

            return {
                records: updatedJobs,
                unpaid:
                    invoice.status === INVOICE_STATUS.OPEN
                        ? isInvoiceSelected
                            ? prev.unpaid - 1
                            : prev.unpaid + 1
                        : prev.unpaid,
                totalUnpaid:
                    invoice.status === INVOICE_STATUS.OPEN
                        ? isInvoiceSelected
                            ? prev.totalUnpaid - invoice.invoiceAmount
                            : prev.totalUnpaid + invoice.invoiceAmount
                        : prev.totalUnpaid,
                totalPastDue:
                    invoice.status === INVOICE_STATUS.PAST_DUE
                        ? isInvoiceSelected
                            ? prev.totalPastDue - 1
                            : prev.totalPastDue + 1
                        : prev.totalPastDue,
                pastDue:
                    invoice.status === INVOICE_STATUS.PAST_DUE
                        ? isInvoiceSelected
                            ? prev.pastDue - 1
                            : prev.pastDue + 1
                        : prev.pastDue,
                totalRecords: isInvoiceSelected ? prev.totalRecords - 1 : prev.totalRecords + 1,
                totalAmount: isInvoiceSelected
                    ? prev.totalAmount - invoice.invoiceAmount
                    : prev.totalAmount + invoice.invoiceAmount,
            }
        })
    }

    return (
        <>
            <InvoiceReportOverView report={report as InvoiceReportType} />
            <Card>
                <CardContent className='overflow-x-scroll green-scrollbar rounded-t-none pb-0'>
                    <Table className='table-auto whitespace-normal mx-auto text-zentive-black text-base'>
                        <TableHeader className='whitespace-nowrap'>
                            <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                                {tableHeader.map((header, index) => (
                                    <TableHead
                                        key={index}
                                        className={cn(
                                            'font-semibold text-zentive-gray-medium text-base whitespace-nowrap',
                                        )}
                                    >
                                        <div className='flex flex-row items-center justify-start gap-2'>
                                            {' '}
                                            {index === 0 && (
                                                <Checkbox
                                                    onCheckedChange={handleCheckAllChange}
                                                    checked={
                                                        isAllChecked ||
                                                        exportAtom?.records?.length ===
                                                            report?.content?.report?.length
                                                    }
                                                    className='mt-[1px]'
                                                />
                                            )}
                                            {header}
                                        </div>
                                    </TableHead>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {Array.isArray(report?.content?.report) &&
                                report?.content?.report?.length > 0 &&
                                report?.content?.report?.map((r: InvoiceReportRecordType) => (
                                    <TableRow
                                        key={r.invoiceId as string}
                                        className='whitespace-nowrap text-base'
                                    >
                                        <TableCell className='text-zentive-black  flex flex-row gap-2 items-center'>
                                            <Checkbox
                                                onCheckedChange={() => handleCheckChange(r)}
                                                checked={exportAtom?.records?.some(
                                                    (rec) => r.invoiceId === rec.invoiceId,
                                                )}
                                            />
                                            <NavLink
                                                to={
                                                    r.invoiceNumber
                                                        ? `/financial-management/invoicing/view-invoice/${r.invoiceId}`
                                                        : '#'
                                                }
                                            >
                                                <Button
                                                    className=' text-zentive-blue-dark font-semibold text-base'
                                                    variant={'link'}
                                                >
                                                    {r.invoiceNumber
                                                        ? RECORD_PREFIX.invoice +
                                                          formatRecordNumber(r.invoiceNumber)
                                                        : 'N/A'}
                                                </Button>
                                            </NavLink>
                                        </TableCell>
                                        <TableCell>
                                            <p>{r.customerName}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{r.email}</p>
                                        </TableCell>
                                        <TableCell className='text-right'>
                                            <p>{displayCurrency(r.invoiceAmount)}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>
                                                {formatToUTCWithOffset(
                                                    r.date,
                                                    tz?.timeZoneId as string,
                                                    US_FORMAT,
                                                )}
                                            </p>
                                        </TableCell>
                                        <TableCell className={INVOICE_LABEL_COLOR[r.status]}>
                                            <p>{INVOICE_STATUS_LABEL[r.status]}</p>
                                        </TableCell>
                                        <TableCell className='text-right'>
                                            <p>{r.phoneNumber}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{r.address}</p>
                                        </TableCell>
                                        {/* <TableCell>
                                            <p>{r.processedBy}</p>
                                        </TableCell> */}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </CardContent>
                <div className='p-5 pb-0 flex flex-row items-center'>
                    <Pagination
                        pagination={pagination}
                        setPagination={setPagination}
                        itemsPerPage={report?.meta?.pageSize ?? 5}
                        totalRecords={report?.meta?.totalRecords ?? 0}
                    />
                </div>
            </Card>
        </>
    )
}
export default InvoiceReportTable
