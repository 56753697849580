import { FullProfileType } from '@/api/profile/schema'
import { isViewedAsAtom, timezoneAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useAtomValue, useSetAtom } from 'jotai'
import { FaPhoneAlt, FaIdCard } from 'react-icons/fa'
import { FaRegCalendar } from 'react-icons/fa6'
import { MdCake } from 'react-icons/md'
import { IoLocationSharp } from 'react-icons/io5'
import { BsFillMenuButtonWideFill } from 'react-icons/bs'
import { MdKeyboardArrowRight } from 'react-icons/md'
import dayjs from 'dayjs'
import { useQuery } from '@tanstack/react-query'
import { getEmployeeById } from '@/api/profile'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Spinner from '@/components/Spinner'
import { HiOutlineLogout } from 'react-icons/hi'
import { signOut } from '@/api/auth'
import { selectedDateAtom, selectedDateFilterAtom, splashScreenAtom } from '@/store/crew'
import {
    COMPLETE_FORMAT_WITHOUT_TIME,
    ISO_8601_WITH_UTC_OFFSET_IOS,
    SPANISH_FORMAT,
} from '@/constants'
import { formatToUTCWithOffsetIos } from '@/utils/time'
import { TimeZoneResult } from '@/hooks/useGeocode'
import { FaUpRightFromSquare } from 'react-icons/fa6'

const CrewProfile = () => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation(['profile'])
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult
    const setIsSplashShown = useSetAtom(splashScreenAtom)
    const setSelectedDate = useSetAtom(selectedDateAtom)
    const setSelectedDateFilter = useSetAtom(selectedDateFilterAtom)

    const { data: employee } = useQuery({
        queryKey: ['employee', user.profileId],
        queryFn: () => getEmployeeById(user.profileId),
    })

    if (!employee)
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-white'>
                <Spinner variant='normal' size='10' />
            </div>
        )

    const formatDateTime = (dateTime: string, timezone: string, format: string) => {
        try {
            return formatToUTCWithOffsetIos(dateTime, timezone, format)
        } catch (error) {
            console.error('Error formatting date:', error)
            return 'Invalid Date'
        }
    }

    const handleSignOut = () => {
        signOut()
        localStorage.removeItem('continueWithoutGeo')
        setIsSplashShown(false)
        setSelectedDate({
            startDate: formatDateTime(
                dayjs().startOf('day').toString(),
                timezone?.timeZoneId as string,
                ISO_8601_WITH_UTC_OFFSET_IOS,
            ),
            endDate: formatDateTime(
                dayjs().endOf('day').toString(),
                timezone?.timeZoneId as string,
                ISO_8601_WITH_UTC_OFFSET_IOS,
            ),
        })

        setSelectedDateFilter({
            startDate: formatDateTime(
                dayjs().startOf('day').toString(),
                timezone?.timeZoneId as string,
                ISO_8601_WITH_UTC_OFFSET_IOS,
            ),
            endDate: formatDateTime(
                dayjs().endOf('day').toString(),
                timezone?.timeZoneId as string,
                ISO_8601_WITH_UTC_OFFSET_IOS,
            ),
        })
    }
    const mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        `${employee?.address?.streetAddress}, ${
            employee?.address?.streetTwoAddress &&
            employee?.address?.streetTwoAddress !== 'undefined'
                ? employee?.address?.streetTwoAddress + ', '
                : ''
        }${employee?.address?.city}, ${employee?.address?.state} ${employee?.address?.zipCode}`,
    )}`

    return (
        <div className='w-full flex flex-col px-6'>
            <div className='bg-zentive-green-light flex flex-row px-4 py-4 gap-x-4 rounded-lg mt-4'>
                {employee?.profileImage ? (
                    <img
                        src={
                            typeof employee?.profileImage === 'string' ? employee?.profileImage : ''
                        }
                        alt={`Profile image`}
                        className='relative flex h-12 w-12 shrink-0 overflow-hidden rounded-full'
                    />
                ) : (
                    <div className='h-[38px] w-[38px] rounded-full bg-gray-300 flex justify-center items-center mr-1'>
                        <span className='text-zentive-black text-base '>
                            {`${employee?.firstName?.charAt(0)?.toUpperCase() ?? ''}${
                                employee?.lastName?.charAt(0)?.toUpperCase() ?? ''
                            }`}
                        </span>
                    </div>
                )}
                <div className='flex flex-col max-w-[220px]'>
                    <p className='text-zentive-green-dark text-base font-semibold'>
                        {employee?.firstName + ' ' + employee?.lastName}
                    </p>
                    <p className='text-zentive-green-dark text-sm'>{employee?.email}</p>
                </div>
            </div>
            <div className='bg-zentive-green-light flex flex-col px-4 py-4 gap-x-4 rounded-[2px] mt-4 justify-center items-center'>
                <p className='text-sm font-normal text-zentive-black'>{t('Pay Rate Per Hour')}</p>
                <p className='text-zentive-black font-semibold text-ellipsis text-base'>
                    $
                    {employee?.compensation?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}
                </p>
            </div>
            <div className='bg-white border-zentive-gray-light flex flex-col px-4 py-4 gap-x-4 rounded-lg mt-4'>
                <div className='flex flex-row border-b -mx-4 pb-1'>
                    <div className='mx-4 flex gap-x-2'>
                        <div>
                            <FaPhoneAlt className='mt-1 h-[14px] w-5 text-zentive-blue-dark' />
                        </div>
                        <div>
                            <p className='font-semibold text-zentive-gray-medium'>
                                {t('Contact Number')}
                            </p>
                            <p className='overflow-wrap text-sm break-words'>
                                {employee?.phoneNumber ?? '--'}
                            </p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row -mx-4 border-b py-1'>
                    <div className='mx-4 flex gap-x-2'>
                        <div>
                            <MdCake className='mt-[2px] h-[18px] w-5 text-zentive-blue-dark' />
                        </div>
                        <div>
                            <p className='font-semibold text-zentive-gray-medium'>
                                {t('Birthday')}
                            </p>
                            <p className='text-sm'>
                                {dayjs(employee?.dateOfBirth).format(
                                    i18n.language === 'es'
                                        ? SPANISH_FORMAT
                                        : COMPLETE_FORMAT_WITHOUT_TIME,
                                )}
                            </p>
                        </div>
                    </div>
                </div>

                <div className='flex flex-row gap-x-2 -mx-4 border-b py-1 items-center'>
                    <div className='flex mx-4'>
                        <IoLocationSharp className='mt-[2px] h-[18px] w-5 text-zentive-blue-dark' />
                        <div className='ml-2 flex flex-col'>
                            <p className='font-semibold text-zentive-gray-medium'>
                                {t('Complete Address')}
                            </p>
                            <a
                                href={mapUrl}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='text-blue-500 underline'
                            >
                                <p className='text-sm'>
                                    {`${employee?.address?.streetAddress}, ${
                                        employee?.address?.streetTwoAddress &&
                                        employee?.address?.streetTwoAddress !== 'undefined'
                                            ? employee?.address?.streetTwoAddress + ', '
                                            : ''
                                    }${employee?.address?.city}, ${employee?.address
                                        ?.state} ${employee?.address?.zipCode}`}
                                </p>
                            </a>
                        </div>
                    </div>
                    <div className='flex items-center ml-auto mr-4'>
                        <a href={mapUrl} target='_blank' rel='noopener noreferrer'>
                            <FaUpRightFromSquare className='h-4 w-4 ' />
                        </a>
                    </div>
                </div>

                <div className='flex flex-row -mx-4 border-b py-1'>
                    <div className='flex mx-4 gap-x-2'>
                        <div>
                            <FaIdCard className='mt-1 h-4 w-5 text-zentive-blue-dark' />
                        </div>
                        <div>
                            <p className='font-semibold text-zentive-gray-medium'>{t('License')}</p>
                            <p className='overflow-wrap text-sm break-words'>
                                {employee?.caLicense ? employee?.caLicense : '--'}
                            </p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row gap-x-2 py-1'>
                    <div>
                        <FaRegCalendar className='mt-1 h-4 w-5 text-zentive-blue-dark' />
                    </div>
                    <div>
                        <p className='font-semibold text-zentive-gray-medium'>{t('Date Hired')}</p>
                        <p className='text-sm'>
                            {dayjs(user?.createdAt).format(
                                i18n.language === 'es' ? 'DD/MM/YYYY' : 'MMMM DD, YYYY',
                            )}
                        </p>
                    </div>
                </div>
            </div>
            <div
                className='flex flex-row bg-white px-4 py-4 gap-x-4 rounded-lg mt-4 justify-between mb-4'
                role='button'
                onClick={() => navigate('/crew/profile/history')}
            >
                <div className='flex flex-row gap-x-2'>
                    <BsFillMenuButtonWideFill className='mt-1 h-4 w-5 text-zentive-blue-dark' />
                    <p>{t('Work History')}</p>
                </div>
                <MdKeyboardArrowRight className='h-5 w-5' />
            </div>
            <div
                className='flex items-center justify-center bg-zentive-red-dark px-4 py-4 gap-x-2 rounded-sm mb-24'
                role='button'
                onClick={handleSignOut}
            >
                <HiOutlineLogout className='h-5 w-5 text-white' />
                <p className='font-semibold text-white'>{t('Logout')}</p>
            </div>
        </div>
    )
}

export default CrewProfile
