import GrossNetRevenueChart from './GrossNetRevenueChart'
import { JobsChart } from './JobsChart'
import BreadCrumbs from '@/components/BreadCrumbs'
import QuoteChart from './QuoteChart'
import InvoiceChart from './InvoiceChart'
import ExpenseChart from './ExpenseChart'
import GeolocationWarningModal from '@/components/GeolocationWarningModal'
import { useEffect, useState } from 'react'
import { toast } from '@/hooks/useToast'
import { fetchTimezone } from '@/hooks/useGeocode'
import { timezoneAtom } from '@/store/auth'
import { useSetAtom } from 'jotai'

const OwnerDashboard = () => {
    const [showGeolocationWarning, setShowGeolocationWarning] = useState(false)
    const setTimezone = useSetAtom(timezoneAtom)

    useEffect(() => {
        handleEnableLocation()
    }, [])

    const handleEnableLocation = () => {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                setShowGeolocationWarning(false)
                sessionStorage.setItem('geolocationWarningShown', 'true')
                const { latitude, longitude } = position.coords
                const timezone = await fetchTimezone({ lat: latitude, lng: longitude })
                setTimezone(timezone)
                if (sessionStorage.getItem('geolocationWarningShown') !== 'true') {
                    toast({
                        title: 'Location permission granted',
                        description: 'Location permission has been enabled',
                        variant: 'default',
                    })
                    sessionStorage.setItem('geolocationWarningShown', 'true')
                }
            },
            (error) => {
                if (
                    error.code === error.PERMISSION_DENIED &&
                    sessionStorage.getItem('geolocationWarningShown') !== 'true'
                ) {
                    setShowGeolocationWarning(true)
                    toast({
                        title: 'Location permission denied',
                        description: 'Please enable location permission to continue',
                        variant: 'destructive',
                    })
                }
            },
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            },
        )
    }

    // const checkAndRequestGeolocation = async () => {
    //     const permissionStatus = await navigator.permissions.query({ name: 'geolocation' })
    //     console.log('🚀 ~ checkAndRequestGeolocation ~ permissionStatus:', permissionStatus)

    //     if (permissionStatus.state === 'denied') {
    //         handleEnableLocation()
    //     } else {
    //         handleEnableLocation()
    //     }
    // }

    // In your JSX:

    return (
        <>
            <div className='h-full'>
                <BreadCrumbs titleName='Dashboard' />
                <div className='flex flex-col gap-10'>
                    <div className='mt-10'>
                        <JobsChart />
                    </div>
                    <div className='h-full flex flex-col justify-center items-center'>
                        <GrossNetRevenueChart />
                    </div>
                    <div className='grid grid-cols-3 gap-4 justify-between items-start w-full'>
                        <div className='flex-1'>
                            <QuoteChart />
                        </div>
                        <div className='flex-1'>
                            <InvoiceChart />
                        </div>
                        <div className='flex-1'>
                            <ExpenseChart />
                        </div>
                    </div>
                </div>
            </div>
            <GeolocationWarningModal
                open={showGeolocationWarning}
                setOpen={setShowGeolocationWarning}
            />
        </>
    )
}

export default OwnerDashboard
