import 'react-datepicker/dist/react-datepicker.css'
import { Card, CardContent, CardFooter } from '@/components/Card'
import SortableList from './SortableList'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { getCounts, getProfilesPolyline } from '@/api/routing'
import {
    ArrangementAndOptimizeType,
    OwnerForemanProfileType,
    RouteOptimizeType,
    RoutePolylineType,
} from '@/api/routing/schema'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { isPolylineLoadingAtom, routeOptimizeAtom, routePolylineAtom } from '@/store/route'
import Tooltip from '@/components/Tooltip'
import { IoIosInformationCircle } from 'react-icons/io'
import { isViewedAsAtom, viewedAsUserAtom, userAtom } from '@/store/auth'
import { useState, useEffect } from 'react'
import ApplyAlert from './ApplyAlert'

const PropertyList = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const setPolylineList = useSetAtom(routePolylineAtom)
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [isPolylineLoading, setIsPolylineLoading] = useAtom(isPolylineLoadingAtom)
    const setrouteOptimize = useSetAtom(routeOptimizeAtom)

    const { mutate: getPolylineMu } = useMutation<
        RouteOptimizeType[],
        AxiosError,
        RouteOptimizeType[]
    >({
        mutationFn: (profiles) => {
            setIsPolylineLoading(true)
            return getProfilesPolyline(profiles)
        },
        onSuccess: (data: RouteOptimizeType[]) => {
            refetch()
            const profiles = data
                .filter((profile) => profile.isIncluded)
                ?.map((profile) => profile.profile)
                .filter((profile): profile is OwnerForemanProfileType => !!profile)

            const polylines: RoutePolylineType = {
                customers: profiles,
                encodedPolyline: data[0].encodedPolyline!,
            }
            setPolylineList(polylines)
            setrouteOptimize(data)

            setIsPolylineLoading(false)
        },
        onError: () => {
            setIsPolylineLoading(false)
        },
    })

    const { data: counts, refetch } = useQuery<unknown, AxiosError, ArrangementAndOptimizeType>({
        queryKey: ['counts'],
        queryFn: () => getCounts(user?.businessId as string),
    })

    useEffect(() => {
        if (counts?.arrangementCount! > 0 && !isPolylineLoading) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [counts, isPolylineLoading])

    return (
        <div className=' flex flex-col w-[30%] mr-4 '>
            <div className='w-full flex flex-row text-zentive-blue-dark font-semibold bg-zentive-gray-light pl-4 py-2 rounded-t-xl items-center'>
                <span>Properties</span>
                <Tooltip
                    position='bottom'
                    alignment='start'
                    content={
                        <span>
                            Drag the tiles below to re-arrange the stops or click
                            <span className=' text-zentive-green-medium'> 'Optimize Stops' </span>
                            to automatically arrange it.
                        </span>
                    }
                    fontstyle='font-sans'
                    width='64'
                >
                    <IoIosInformationCircle className=' text-black ml-2 h-5 w-5' />
                </Tooltip>
            </div>
            <Card className=' w-full rounded-none border-none'>
                <CardContent className='p-0'>
                    <SortableList />
                </CardContent>
                <CardFooter className='lg:flex lg:justify-end rounded-b-md bg-[#EBEBEB] pr-4 py-2 '>
                    <div className='flex flex-col items-center justify-center mx-auto mt-2'>
                        <div className='flex flex-row items-center justify-center mx-auto '>
                            <ApplyAlert
                                count={counts?.arrangementCount!}
                                getPolylineMu={getPolylineMu}
                                isDisabled={isDisabled}
                            />
                            <Tooltip
                                position='bottom'
                                alignment='start'
                                content={
                                    <span>
                                        You only have 10 chances a day to arrange your route. Click
                                        "Apply Changes" to update the map. Then click the "Save"
                                        button below to save your route.
                                    </span>
                                }
                                fontstyle='font-sans'
                                width='64'
                            >
                                <IoIosInformationCircle className='text-black ml-2 h-6 w-6 ' />
                            </Tooltip>
                        </div>
                        <span className=' text-sm text-zentive-gray-medium mt-2'>
                            Remaining arrangements for today: {10 - (counts?.arrangementCount ?? 0)}{' '}
                            of 10
                        </span>
                    </div>
                </CardFooter>
            </Card>
        </div>
    )
}

export default PropertyList
