import GeolocationWarningModal from '@/components/GeolocationWarningModal'
import { fetchTimezone } from '@/hooks/useGeocode'
import { timezoneAtom } from '@/store/auth'
import { useSetAtom } from 'jotai'
import { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

const CrewPortal = () => {
    const [showGeolocationWarning, setShowGeolocationWarning] = useState(false)
    const setTimezone = useSetAtom(timezoneAtom)

    const handleGeolocationSuccess = async (position: GeolocationPosition) => {
        setShowGeolocationWarning(false)
        const previousValue = sessionStorage.getItem('geolocationWarningShown')
        sessionStorage.setItem('geolocationWarningShown', 'true')

        const { latitude, longitude } = position.coords
        const timezone = await fetchTimezone({ lat: latitude, lng: longitude })
        setTimezone(timezone)

        if (previousValue !== 'true') {
            localStorage.setItem('continueWithoutGeo', 'false')
        }
    }

    const handleGeolocationError = (error: GeolocationPositionError) => {
        if (
            error.code === error.PERMISSION_DENIED &&
            localStorage.getItem('continueWithoutGeo') !== 'true'
        ) {
            setShowGeolocationWarning(true)
            localStorage.setItem('geolocationWarningShown', 'false')
        }
    }

    const handleEnableLocation = () => {
        navigator.geolocation.getCurrentPosition(handleGeolocationSuccess, handleGeolocationError, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        })
    }

    // const checkAndRequestGeolocation = async () => {
    //     // const permissionStatus = await navigator.permissions.query({ name: 'geolocation' })
    //     handleEnableLocation()
    // }

    useEffect(() => {
        handleEnableLocation()
    }, [])

    return (
        <div className='flex flex-col h-full'>
            <GeolocationWarningModal
                open={showGeolocationWarning}
                setOpen={setShowGeolocationWarning}
                // onEnableLocation={checkAndRequestGeolocation}
            />
            <Outlet />
        </div>
    )
}

export default CrewPortal
