import { useParams } from 'react-router-dom'
import BackButtonTopBar from '../../Common/BackButtonTopBar'
import { Label } from '@radix-ui/react-label'

import {  useQueryClient } from '@tanstack/react-query'

import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { US_FORMAT, US_FORMAT_WITH_TIME_MERIDIEM_DAY } from '@/constants'
import { ReadJobType } from '@/api/job/schema'

const CrewInternalNote = () => {
    const { t, i18n } = useTranslation(['view-job'])
    const { jobId } = useParams()


    const queryClient = useQueryClient()
    const jobDetails = queryClient.getQueryData<ReadJobType>(['jobById', jobId])
    

    return (
        <div className='flex flex-col justify-between items-center'>
            <BackButtonTopBar title={t('Notes')} />
            <div className='flex flex-col w-10/12 max-w-[1024px] border border-zentive-green-dark p-3 rounded-lg bg-white mx-4'>
            <div className='w-full mx-auto py-3'>
                    <div>
                        <div className='p-1'>
                            <Label className='flex justify-end text-[12px] mr-2 text-zentive-gray-medium'>
                                {dayjs(jobDetails?.updatedAt).format(
                                    i18n.language === 'en'
                                        ? US_FORMAT + ' h:mmA'
                                        : US_FORMAT_WITH_TIME_MERIDIEM_DAY,
                                )}
                            </Label>
                            <div className='border-white shadow-none text-[#191A0A] h-[350px] min-h-[350px] max-h-[400px] text-[16px]'>
                                {jobDetails?.description}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CrewInternalNote
