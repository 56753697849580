import stripeLogo from '@/assets/private/stripe_logo.svg'
import { PAYMENT_BTN_STYLE } from '@/constants'
import { cn } from '@/utils/helper'
import { Button } from '@/components/Button'
import { useStripeConnect } from '@/hooks/useStripeConnect'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import useStripeConnectStatus from '@/hooks/useStripeConnectStatus'
import { useQuery } from '@tanstack/react-query'
import { getStripeConntectedBusinessProfile } from '@/api/business'

const StripeAccount = () => {
    const user = useViewedAsUser()

    const { isPending, handleConnect } = useStripeConnect(user?.profileId)

    const { data: isConnectedToStripe } = useStripeConnectStatus()

    const { data: stripeProfile } = useQuery({
        queryKey: ['stripeAccountProfile', user?.stripeConnectedAccId],
        queryFn: () => getStripeConntectedBusinessProfile(user?.stripeConnectedAccId as string),
        enabled: isConnectedToStripe,
    })

    return (
        <section>
            {!isConnectedToStripe ? (
                <div className='bg-white pt-5 pb-7 px-5'>
                    <div className='flex flex-col mx-auto mt-10'>
                        <img
                            src={stripeLogo}
                            alt='Jigsaw Icon'
                            className='w-[20%] m-auto h-auto max-w-md mb-10'
                        />
                        <span className='m-auto text-2xl text-zentive-green-dark font-bold tracking-wide mb-4'>
                            No Linked Stripe Account
                        </span>
                        <span className='m-auto text-base tracking-wide mb-10'>
                            To link your Stripe account, please click 'Set up Stripe Account' button
                            below.
                        </span>
                        <Button
                            className={cn(
                                PAYMENT_BTN_STYLE,
                                'w-[243.5px] m-auto mb-10 bg-zentive-green-dark hover:bg-zentive-green-medium',
                            )}
                            disabled={isPending}
                            onClick={handleConnect}
                            type='button'
                        >
                            Set Up Stripe Account
                        </Button>
                    </div>
                </div>
            ) : (
                <div className='bg-white pt-5 pb-7 px-10'>
                    <div className='flex flex-col mx-auto mt-10'>
                        <img
                            src={stripeLogo}
                            alt='Jigsaw Icon'
                            className='w-[15%] m-auto h-auto max-w-md mb-10'
                        />
                        <span className='m-auto text-2xl text-zentive-green-dark font-bold tracking-wide mb-4'>
                            Stripe Account is Linked
                        </span>
                    </div>

                    <div className='flex flex-col gap-2 items-start'>
                        <span className='text-lg tracking-wide'>Account Information</span>

                        <span className='flex flex-col gap-2 border border-1 p-5 w-full'>
                            <span className='text-zentive-gray-dark text-base font-semibold'>
                                Business Name
                            </span>
                            <span className='text-zentive-gray-medium text-base'>
                                {stripeProfile?.name === '' || stripeProfile?.name === undefined
                                    ? '--'
                                    : stripeProfile?.name}
                            </span>
                        </span>

                        <span className='flex flex-col gap-2 border border-1 p-5 w-full'>
                            <span className='text-zentive-gray-dark text-base font-semibold'>
                                Email Address
                            </span>
                            <span className='text-zentive-gray-medium text-base'>
                                {stripeProfile?.email ?? '--'}
                            </span>
                        </span>

                        <span className='flex flex-col gap-2 border border-1 p-5 w-full'>
                            <span className='text-zentive-gray-dark text-base font-semibold'>
                                Account Status
                            </span>
                            <span className='text-zentive-gray-medium text-base text-zentive-green-dark'>
                                Connected
                            </span>
                        </span>
                    </div>
                </div>
            )}
        </section>
    )
}

export default StripeAccount
