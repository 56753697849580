import { CardTitle } from '@/components/Card'
import { QuoteType } from '@/api/quoting/schema'
import { computeQuote } from '@/utils/calc'
import { displayCurrency } from '@/utils/helper'

const BillingAmount = ({ quote }: { quote: QuoteType }) => {
    const {
        discount,
        laborCost,
        // total,
        totalAfterDiscount,
        // taxName,
        productsTaxPercentage,
        servicesTaxPercentage,
        expensesTaxPercentage,
        productsTaxAmount,
        servicesTaxAmount,
        expensesTaxAmount,
        // totalTaxPercentage,
        // totalTaxAmount,
    } = computeQuote(quote, false)

    return (
        <div className='border border-[#EBEBEB] p-6 my-16 rounded-md'>
            <CardTitle className='text-[18px] mb-6'>Billing Amount</CardTitle>
            <div className='flex flex-row justify-between px-4 border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                <div className='font-semibold'>Labor Cost</div>
                {quote?.showLaborCost ? (
                    <div className=''>{displayCurrency(laborCost)}</div>
                ) : (
                    <div className=''>
                        <span> $0.00 </span>
                    </div>
                )}
            </div>
            <div className='flex flex-row justify-between px-4 border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                <div className='font-semibold'>Subtotal</div>
                <div className=''>${quote?.subTotal!.toFixed(2)}</div>
            </div>

            <div className='flex flex-row justify-between px-4 border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                <div className='font-semibold'>
                    Discount{' '}
                    {quote?.isDiscountPercent ? (
                        <span className='text-zentive-blue-dark'>({quote?.discount}%)</span>
                    ) : (
                        <></>
                    )}
                </div>
                <div className=''>
                    <span className='text-zentive-blue-dark'>-{displayCurrency(discount)}</span>{' '}
                </div>
            </div>
            <div className='flex flex-row justify-between px-4 border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                <div className='font-semibold'>Total After Discount</div>
                <div className=''>{displayCurrency(totalAfterDiscount)}</div>
            </div>
            <div className='flex flex-row justify-between px-4 h-10 mb-42 text-zentive-gray-medium'>
                <div className='font-semibold'>Tax</div>
                <div className=''> {displayCurrency(quote?.tax ?? 0)}</div>
            </div>
            <div className='flex flex-col justify-between h-24 text-zentive-gray-medium mb-6'>
                {productsTaxAmount > 0 && (
                    <div className='flex flex-row justify-between px-4'>
                        <p className='pl-4'>- Products {productsTaxPercentage}% </p>
                        <p>${productsTaxAmount.toFixed(2)}</p>
                    </div>
                )}
                {servicesTaxAmount > 0 && (
                    <div className='flex flex-row justify-between px-4'>
                        <p className='pl-4'>- Services {servicesTaxPercentage}% </p>
                        <p>${servicesTaxAmount.toFixed(2)}</p>
                    </div>
                )}
                {expensesTaxAmount > 0 && (
                    <div className='flex flex-row justify-between px-4'>
                        <p className='pl-4'>- Expenses {expensesTaxPercentage}% </p>
                        <p>${expensesTaxAmount.toFixed(2)}</p>
                    </div>
                )}
            </div>
            <div className='flex flex-row justify-between px-4 pt-4 border-t-[6px] border-zentive-gray-medium h-16 text-zentive-gray-medium'>
                <div className='font-semibold'>Total</div>
                <div className='text-zentive-green-dark font-semibold'>
                    {displayCurrency(quote?.totalAmount ?? 0)}
                </div>
            </div>
        </div>
    )
}

export default BillingAmount
