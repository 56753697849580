import { CardTitle } from '@/components/Card'
import AddTaxDialog from './AddTaxDialog'
import { startTransition, useEffect, useState } from 'react'
import AddDiscountDialog from './AddDiscountDialog'
import { useFormContext } from 'react-hook-form'
import { customerInvoiceAtom, expenseAtom, invoiceContentAtom, invoiceTaxAtom } from '@/store/owner'
import { useAtom, useAtomValue } from 'jotai'
import { Dialog, DialogTrigger } from '@/components/Dialog'
import { CiTrash } from 'react-icons/ci'
import { InvoiceType } from '@/api/invoicing/schema'
import LaborCostDialog from '@/components/LaborCostDialog'
import { jobGenerateInvoiceAtom, selectedJobForInvoiceAtom } from '@/store/job'
import { DEFAULT_ADDRESS, DEFAULT_QUOTE_TAX_VALUE } from '@/constants'
import { displayCurrency, displayWithDecimals } from '@/utils/helper'
import FinancialManagementTaxes from '@/components/FinancialManagement/Taxes'
import {
    calculateGrossIncome,
    calculateNetIncome,
    calculateStripeExclusiveTax,
    calculateStripeTaxWithDiscount,
    roundStripe,
} from '@/utils/calc'
import { businessIdAtom } from '@/store/auth'

const BillingAmount = () => {
    const invoiceContent = useAtomValue(invoiceContentAtom)

    const generateInvoiceData = useAtomValue(jobGenerateInvoiceAtom)
    const expenses = useAtomValue(expenseAtom)
    const jobForInvoicing = useAtomValue(selectedJobForInvoiceAtom)

    const businessId = useAtomValue(businessIdAtom)

    const customer = useAtomValue(customerInvoiceAtom)
    const [tax, setTax] = useState({ name: '', tax: 0, taxAmount: 0 })
    const [taxes, setTaxes] = useAtom(invoiceTaxAtom)

    const [discount, setDiscount] = useState({
        percentage: 0.0,
        amount: 0.0,
        isPercent: false,
        totalAfterDiscount: 0.0,
        discountId: '',
    })

    const [estimate, setEstimate] = useState(0)
    const [estimatePercentage, setEstimatePercentage] = useState(0)
    const [] = useState(0)
    const [subTotalWithDiscount, setSubTotalWithDiscount] = useState(0)

    const { setValue } = useFormContext<InvoiceType>()

    const totalInvoiceExpenses = roundStripe(
        expenses.length > 0
            ? expenses
                  .filter((exp) => !exp.isNotIncludedInInvoice)
                  .reduce((total, expense) => total + (expense.total || 0), 0)
            : 0.0,
    )

    const productsTaxAmount =
        discount.amount > 0
            ? calculateStripeTaxWithDiscount(
                  invoiceContent.totalProduct,
                  taxes.productTax,
                  discount.amount,
                  invoiceContent.subTotal,
              )
            : calculateStripeExclusiveTax(invoiceContent.totalProduct, taxes?.productTax)

    const servicesTaxAmount =
        discount.amount > 0
            ? calculateStripeTaxWithDiscount(
                  invoiceContent.totalService,
                  taxes.serviceTax,
                  discount.amount,
                  invoiceContent.subTotal,
              )
            : calculateStripeExclusiveTax(invoiceContent.totalService, taxes?.serviceTax)

    const expensesTaxAmount =
        discount.amount > 0
            ? calculateStripeTaxWithDiscount(
                  totalInvoiceExpenses,
                  taxes.expenseTax,
                  discount.amount,
                  invoiceContent.subTotal,
              )
            : calculateStripeExclusiveTax(totalInvoiceExpenses, taxes?.expenseTax)

    const calcTotalTaxes = productsTaxAmount + servicesTaxAmount + expensesTaxAmount

    const subtotalWithDiscount = invoiceContent.subTotal - discount.amount

    const total = subtotalWithDiscount + calcTotalTaxes
    const handleRemoveDiscount = () => {
        setDiscount({
            percentage: 0,
            amount: 0,
            isPercent: false,
            totalAfterDiscount: Number(invoiceContent.subTotal.toFixed(2)),
            discountId: '',
        })
        setValue('discountId', undefined)
    }

    const handleRemoveTax = () => {
        setTaxes(DEFAULT_QUOTE_TAX_VALUE)
        setValue('taxId', '')
    }

    // const getTaxesTotalPercentage = () => (calcTotalTaxes / discount.totalAfterDiscount) * 100

    useEffect(() => {
        if (jobForInvoicing?.quote != undefined && generateInvoiceData == null) {
            startTransition(() => {
                const discountAmount = roundStripe(
                    discount.isPercent
                        ? (invoiceContent.subTotal * roundStripe(discount.percentage)) / 100
                        : discount.amount,
                )

                setDiscount((prev) => ({
                    ...prev,
                    amount: discountAmount,
                }))

                const subTotalAfterDiscount = invoiceContent.subTotal - discountAmount

                setDiscount((prevDiscount: any) => ({
                    ...prevDiscount,
                    totalAfterDiscount: subTotalAfterDiscount,
                }))

                setSubTotalWithDiscount(subTotalAfterDiscount)

                const taxAmount = jobForInvoicing?.quote?.tax!
                setValue('tax', roundStripe(calcTotalTaxes))

                const taxPercentage =
                    (jobForInvoicing?.quote?.tax! / invoiceContent?.subTotal) * 100

                setTax((prevTax) => ({ ...prevTax, taxAmount: taxAmount, tax: taxPercentage }))
            })
        } else if (generateInvoiceData) {
            startTransition(() => {
                setDiscount((prevDiscount: any) => ({
                    ...prevDiscount,
                    isPercent: generateInvoiceData.isDiscountPercent,
                }))
                if (generateInvoiceData.isDiscountPercent) {
                    setDiscount((prevDiscount: any) => ({
                        ...prevDiscount,
                        isPercent: generateInvoiceData.isDiscountPercent,
                        percentage: generateInvoiceData.discount,
                        amount: (invoiceContent.subTotal * generateInvoiceData.discount!) / 100,
                    }))
                } else {
                    setDiscount((prevDiscount: any) => ({
                        ...prevDiscount,
                        amount: generateInvoiceData.discount,
                    }))
                }

                const discountAmount = discount.isPercent
                    ? (invoiceContent.subTotal * discount.percentage) / 100
                    : discount.amount

                const subTotalAfterDiscount = invoiceContent.subTotal - discountAmount

                setDiscount((prevDiscount: any) => ({
                    ...prevDiscount,
                    totalAfterDiscount: isNaN(subTotalAfterDiscount) ? 0 : subTotalAfterDiscount,
                }))

                setSubTotalWithDiscount(subTotalAfterDiscount)

                const taxAmount = generateInvoiceData.tax!
                setValue('tax', roundStripe(calcTotalTaxes))

                const taxPercentage = (generateInvoiceData.tax! / invoiceContent.subTotal) * 100

                setTax((prevTax) => ({ ...prevTax, taxAmount: taxAmount, tax: taxPercentage }))
            })
        } else {
            startTransition(() => {
                const discountAmount = roundStripe(
                    discount.isPercent
                        ? (invoiceContent.subTotal * roundStripe(discount.percentage)) / 100
                        : discount.amount,
                )

                setDiscount((prev) => ({
                    ...prev,
                    amount: discountAmount,
                }))

                const taxAmount = subTotalWithDiscount * (tax.tax / 100)

                setValue('tax', roundStripe(calcTotalTaxes))
                setValue('expense', expenses)
                setValue('status', 'draft')
                setValue('profileId', customer?.profile?.profileId as string)
                setValue('profile', customer?.profile)
                setValue('profile.address', customer?.profile?.address ?? DEFAULT_ADDRESS)
                setValue('businessId', businessId)
                setTax((prevTax) => ({ ...prevTax, taxAmount }))
                setSubTotalWithDiscount(invoiceContent.subTotal - discountAmount)

                const totalAfterDiscountTemp = invoiceContent.subTotal - discountAmount
                setDiscount((prevDiscount: any) => ({
                    ...prevDiscount,
                    totalAfterDiscount: isNaN(totalAfterDiscountTemp) ? 0 : totalAfterDiscountTemp,
                }))
            })
        }
    }, [invoiceContent, generateInvoiceData, , customer?.profile])

    useEffect(() => {
        startTransition(() => {
            setValue('totalAmount', roundStripe(total))
            setValue('subTotal', roundStripe(invoiceContent.subTotal))
            setValue('discount', roundStripe(discount.amount))
            setValue('tax', roundStripe(calcTotalTaxes))
            setValue('laborCost', roundStripe(invoiceContent?.laborCost))
            setValue('isDiscountPercent', discount.isPercent)
            setValue(
                'grossIncome',
                calculateGrossIncome(
                    roundStripe(discount.totalAfterDiscount + calcTotalTaxes),
                    roundStripe(invoiceContent?.laborCost),
                    roundStripe(invoiceContent?.totalProduct),
                ),
            )
            setValue(
                'netIncome',
                calculateNetIncome(
                    calculateGrossIncome(
                        roundStripe(discount.totalAfterDiscount + calcTotalTaxes),
                        roundStripe(invoiceContent?.laborCost),
                        roundStripe(invoiceContent?.totalProduct),
                    ),
                    roundStripe(calcTotalTaxes),
                    roundStripe(invoiceContent?.totalExpense),
                ),
            )
            const estimate =
                discount.totalAfterDiscount > 1
                    ? discount.totalAfterDiscount - invoiceContent?.totalProductsAndServices
                    : 0.0

            setEstimate(estimate)
            setEstimatePercentage(Number((estimate / discount.totalAfterDiscount) * 100))
        })
    }, [discount, expenses, calcTotalTaxes, invoiceContent, customer?.profile])

    return (
        <>
            <div className='border border-[#EBEBEB] p-6 my-16'>
                <CardTitle className='text-lg mb-6'>Billing Amount</CardTitle>
                <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold px-4'>
                        <div className='font-semibold'>Labor Cost</div>
                    </div>
                    {invoiceContent.laborCost > 0 ? (
                        <div className='px-8'>
                            {displayCurrency(invoiceContent.laborCost)}
                            <LaborCostDialog fieldName='laborCost' isForEdit={true} />{' '}
                        </div>
                    ) : (
                        <LaborCostDialog fieldName='laborCost' />
                    )}
                </div>
                <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold px-4'>Subtotal</div>
                    <div className='px-8'>{displayCurrency(invoiceContent.subTotal)}</div>
                </div>
                <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    {discount.amount == 0 && discount.percentage == 0 ? (
                        <>
                            <Dialog>
                                <div className='font-semibold px-4'>Discount</div>
                                <DialogTrigger asChild>
                                    <button
                                        disabled={!customer}
                                        className={`${
                                            customer
                                                ? 'text-zentive-blue-dark'
                                                : 'text-zentive-gray-medium cursor-not-allowed'
                                        } px-8 pb-2 font-semibold`}
                                    >
                                        Add Discount
                                    </button>
                                </DialogTrigger>
                                <AddDiscountDialog
                                    setDiscount={setDiscount}
                                    subTotal={invoiceContent.subTotal}
                                />
                            </Dialog>
                        </>
                    ) : (
                        <>
                            <div className='font-semibold px-4'>
                                Discount{' '}
                                {discount.isPercent ? (
                                    <Dialog>
                                        <DialogTrigger asChild>
                                            <button
                                                type='button'
                                                className='text-zentive-blue-dark font-semibold hover:underline'
                                            >
                                                {discount.percentage}%{' '}
                                            </button>
                                        </DialogTrigger>
                                        <AddDiscountDialog
                                            setDiscount={setDiscount}
                                            subTotal={invoiceContent.subTotal}
                                        />
                                    </Dialog>
                                ) : (
                                    <> </>
                                )}
                            </div>
                            <div className='flex items-center'>
                                <div>{`-${displayCurrency(discount.amount)}`}</div>
                                <button
                                    type='button'
                                    className='ml-1'
                                    onClick={handleRemoveDiscount}
                                >
                                    <CiTrash className='size-7 text-zentive-red-dark' />
                                </button>
                            </div>
                        </>
                    )}
                </div>
                <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold px-4'>Total After Discount</div>
                    <div className='px-8'>{displayCurrency(discount.totalAfterDiscount)}</div>
                </div>
                <div className='flex flex-row justify-between  h-10  text-zentive-gray-medium'>
                    {taxes.productTax == 0 && taxes.serviceTax == 0 && taxes.expenseTax == 0 ? (
                        <>
                            <Dialog>
                                <div className='font-semibold px-4'>Tax</div>
                                <DialogTrigger asChild>
                                    <button
                                        disabled={!customer}
                                        className={`${
                                            customer
                                                ? 'text-zentive-blue-dark'
                                                : 'text-zentive-gray-medium cursor-not-allowed'
                                        } px-8 pb-2 font-semibold`}
                                    >
                                        Add Tax
                                    </button>
                                </DialogTrigger>
                                <AddTaxDialog />
                            </Dialog>
                        </>
                    ) : (
                        <>
                            <div className='font-semibold px-4'>Tax</div>
                            <div className='flex items-center pb-2'>
                                <div className=''>{displayCurrency(calcTotalTaxes)}</div>
                                <button type='button' className='ml-1' onClick={handleRemoveTax}>
                                    <CiTrash className='size-7 text-zentive-red-dark' />
                                </button>
                            </div>
                        </>
                    )}
                </div>
                {taxes.productTax > 0 || taxes.serviceTax > 0 || taxes.expenseTax > 0 ? (
                    <FinancialManagementTaxes
                        productsTaxAmount={productsTaxAmount}
                        servicesTaxAmount={servicesTaxAmount}
                        expensesTaxAmount={expensesTaxAmount}
                    />
                ) : null}
                <div className='flex flex-row justify-between pt-4 border-t-[6px] border-zentive-gray-medium h-16 text-zentive-gray-medium'>
                    <div className='font-semibold px-4'>Total</div>
                    <div className='text-zentive-green-dark font-semibold px-8'>
                        {displayCurrency(roundStripe(subtotalWithDiscount + calcTotalTaxes))}
                    </div>
                </div>
            </div>
            <div className='border border-[#EBEBEB] p-6 my-16 rounded-md'>
                <CardTitle className='text-lg mb-6'>Cost and Estimated Margin</CardTitle>
                <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold px-4'>Product and Service Cost</div>
                    <div className='px-8'>
                        {displayCurrency(invoiceContent.totalProductsAndServices)}
                    </div>
                </div>
                <div className='flex flex-row justify-between h-10 mb-4 text-zentive-gray-medium'>
                    <div className='flex flex-col px-4'>
                        <div className='font-semibold'>Net Profit Margin</div>
                        <div className='font-semibold'>(Excluded of Tax)</div>
                    </div>
                    <div className='text-zentive-green-dark font-semibold px-8'>
                        {`${displayCurrency(estimate)} (${displayWithDecimals(
                            estimatePercentage,
                            2,
                        )}%)`}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BillingAmount
