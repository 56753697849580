    import { useQuery } from '@tanstack/react-query'
import { getJobById } from '@/api/job'
import Spinner from '@/components/Spinner'
import BackButtonTopBar from '../../Common/BackButtonTopBar'
import emptyNotesPlaceholder from '@/assets/private/empty-notes.png'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { Button } from '@/components/Button'
import { useTranslation } from 'react-i18next'
import { FEATURE_RESTRICTIONS, US_FORMAT, US_FORMAT_WITH_TIME_MERIDIEM_DAY } from '@/constants'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import { useAtomValue } from 'jotai'
import { userAtom } from '@/store/auth'
import { useState } from 'react'
import CrewFeatureRestrictionModal from '@/components/CrewFeatureRestrictionModal'

const CrewJobNotes = () => {
    const { t, i18n } = useTranslation(['view-job'])
    const navigate = useNavigate()
    const { jobId } = useParams()
    const user = useAtomValue(userAtom)
    const [restrictionModal, setRestrictionModal] = useState<boolean>(false)

    const { data: job, isLoading } = useQuery({
        queryKey: ['jobById', jobId],
        queryFn: () => getJobById(jobId ?? ''),
    })

    const { data: isFeatureAvailable, isLoading: featureLoading } = useQuery<boolean>({
        queryKey: ['crewNotesFeatureRestriction', user?.businessId],
        queryFn: () =>
            checkSubscriptionFeatureRestriction(
                user?.businessId as string,
                FEATURE_RESTRICTIONS.customFields,
            ),
    })

    if (isLoading) {
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-white'>
                <Spinner variant='normal' size='10' />
            </div>
        )
    }

    return (
        <div className='flex flex-col justify-between items-center space-y-2'>
            <BackButtonTopBar
                title={t('Notes')}
                redirectTo={`/crew/job/${jobId}`}
                showUpgradeBadge={!isFeatureAvailable}
                isLoading={featureLoading}
            />
            {job?.description ? 
                    <div
                    key={'internalNote'}
                    role='button'
                    onClick={() => navigate(`/crew/job/note/internal/${jobId}`)}
                    className='flex flex-col w-10/12 max-w-[1024px] border border-zentive-blue-dark p-3 rounded-lg bg-white mx-4'
                >
                    <p className='text-xs text-right text-gray-500'>
                        {dayjs(job?.updatedAt).format(
                            i18n.language === 'en'
                                ? US_FORMAT + ' h:mmA'
                                : US_FORMAT_WITH_TIME_MERIDIEM_DAY,
                        )}
                    </p>
                    <p className='text-lg mt-auto truncate'>{job?.description ?? 'No notes.'}</p>
                </div>
                : 
                <></>
            }
            {job?.note?.map((note, index) => (
                <div
                    key={index}
                    role='button'
                    onClick={() => navigate(`/crew/job/note/update/${note.noteId}`)}
                    className='flex flex-col w-10/12 max-w-[1024px] border border-zentive-blue-dark p-3 rounded-lg bg-white mx-4'
                >
                    <p className='text-xs text-right text-gray-500'>
                        {dayjs(note?.updatedAt).format(
                            i18n.language === 'en'
                                ? US_FORMAT + ' h:mmA'
                                : US_FORMAT_WITH_TIME_MERIDIEM_DAY,
                        )}
                    </p>
                    <p className='text-lg mt-auto truncate'>{note?.note ?? 'No notes.'}</p>
                </div>
            ))}
            <Button
                onClick={() => {
                    if (isFeatureAvailable) {
                        navigate(`/crew/job/note/add/${jobId}`)
                    } else {
                        setRestrictionModal(true)
                    }
                }}
                className='w-10/12 max-w-[1024px] fixed bottom-4 z-20 px-8 mx-4 bg-white text-zentive-green-dark hover:text-zentive-bg-100'
                disabled={featureLoading}
            >
                {t('Add')}
            </Button>
            {!job?.note && (
                <div className=' px-4 mx-4 my-10'>
                    <div className='flex justify-center bg-white rounded-md'>
                        <img
                            src={emptyNotesPlaceholder}
                            alt='empty-table-placeholder'
                            className='my-20 h-44'
                        />
                    </div>
                    <p className='pb-4 text-center text-2xl text-white font-semibold mt-4'>
                        {t('No notes found')}
                    </p>
                    <p className='pb-4 text-center text-white'>
                        {t(`You currently have no notes to display.`)}
                    </p>
                </div>
            )}
            <CrewFeatureRestrictionModal open={restrictionModal} setOpen={setRestrictionModal} />
        </div>
    )
}

export default CrewJobNotes
