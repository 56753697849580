import axios from 'axios'
import goTrueClient from './goTrueClient'
import { signOut } from './auth'

export const API_URL = import.meta.env.PROD
    ? import.meta.env.VITE_PROD_API_URL
    : import.meta.env.VITE_DEV_API_URL

export const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

export const WEBSITE_URL = import.meta.env.PROD
    ? import.meta.env.VITE_PROD_WEBSITE_URL
    : import.meta.env.VITE_DEV_WEBSITE_URL

export const WEBSOCKET_URL = import.meta.env.PROD
    ? import.meta.env.VITE_PROD_WEBSOCKET_URL
    : import.meta.env.VITE_TEST_WEBSOCKET_URL

axiosInstance.interceptors.request.use(async (config) => {
    const session = await goTrueClient.getSession()

    const accessToken = session.data.session?.access_token

    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
    }

    return config
})

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.data.error === 'Account is Deleted') {
            signOut()
            throw new Error(error.response.data.error)
        }

        return Promise.reject(error)
    },
)
