import { Dispatch, SetStateAction } from 'react'
import { Button } from '@/components/Button'
import { Modal } from '@/components/Modal'
import { InvoiceType } from '@/api/invoicing/schema'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { payInvoiceManually } from '@/api/invoicing'
import { useToast } from '@/hooks/useToast'
import { useNavigate } from 'react-router-dom'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'

interface MarkInvoiceAsPaidModalProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    invoice: InvoiceType | null
}

const MarkInvoiceAsPaidModal: React.FC<MarkInvoiceAsPaidModalProps> = ({
    open,
    setOpen,
    invoice,
}) => {
    const { toast } = useToast()
    const queryClient = useQueryClient()

    const navigate = useNavigate()

    const updateMutation = useMutation({
        mutationKey: ['updateQuoteStatus'],
        mutationFn: () => payInvoiceManually(invoice?.invoiceNumber!),
        onError: (err) => {
            console.error(err)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['InvoiceType'] })
            toast({
                description: 'Invoice marked as paid!',
                variant: 'default',
            })
            navigate('/financial-management/invoicing')
        },
    })

    const handlePayInvoice = () => {
        invoice && updateMutation.mutate()
    }

    return (
        <Modal
            isOpen={open}
            isHideCloseButton
            onClose={() => {
                setOpen(false)
            }}
            title=''
            titleClassName=''
            containerClassName='max-w-[600px]'
        >
            <div className='flex gap-5 px-10'>
                <div className='flex flex-row gap-5'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-16 w-16 rounded-full'>
                        <HiOutlineExclamationTriangle className='h-6 w-6 mx-5  yx-5 text-red-500' />
                    </div>
                    <div className='flex flex-col'>
                        <h1 className='text-left font-semibold text-2xl text-gray-900 mt-2.5 capitalize'>
                            Mark as Paid
                        </h1>
                        <p className='text-left text-sm text-gray-500 mt-5'>
                            Are you sure you want to mark this account as paid?
                        </p>
                    </div>
                </div>
            </div>
            <div className='mt-6 flex justify-end gap-x-4 bg-gray-300 py-6 px-6'>
                <Button
                    onClick={() => setOpen(false)}
                    className='w-97 h-11 text-base font-semibold cursor-pointer'
                    variant={'outline'}
                >
                    Cancel
                </Button>

                <Button
                    onClick={handlePayInvoice}
                    className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium cursor-pointer'
                    disabled={updateMutation.isPending}
                >
                    Mark as Paid
                </Button>
            </div>
        </Modal>
    )
}

export default MarkInvoiceAsPaidModal
