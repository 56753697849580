import { CardTitle } from '@/components/Card'
import { invoiceUserAtom } from '@/store/owner'
import { computeInvoice } from '@/utils/calc'
import { displayCurrency, displayWithDecimals } from '@/utils/helper'
import { useAtomValue } from 'jotai'

const BillingAmount = () => {
    const invoice = useAtomValue(invoiceUserAtom)
    const {
        cost,
        estimate,
        laborCost,
        estimatePercentage,
        subTotal,
        productsTaxPercentage,
        servicesTaxPercentage,
        expensesTaxPercentage,
        productsTaxAmount,
        servicesTaxAmount,
        expensesTaxAmount,
        totalTaxAmount,
        totalAfterDiscount,
        total,
    } = computeInvoice(invoice)

    return (
        <>
            <div className='border border-[#EBEBEB] p-6 my-16 rounded-md'>
                <CardTitle className='text-lg mb-6'>Billing Amount</CardTitle>
                <div className='flex flex-row justify-between px-4 border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold'>Labor Cost</div>
                    <div className=''>{displayCurrency(laborCost)}</div>
                </div>
                <div className='flex flex-row justify-between px-4 border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold'>Subtotal</div>
                    <div className=''>{displayCurrency(subTotal)}</div>
                </div>

                <div className='flex flex-row justify-between px-4 border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold'>
                        Discount{' '}
                        {invoice?.isDiscountPercent && (
                            <span className='text-zentive-blue-dark'>
                                (
                                {Math.round(
                                    !invoice?.discount
                                        ? 0.0
                                        : (Number(invoice.discount) / subTotal) * 100,
                                )}
                                %)
                            </span>
                        )}
                    </div>
                    <div className=''>
                        <span className='text-zentive-blue-dark'>
                            -{displayCurrency(invoice?.discount ?? 0)}
                        </span>{' '}
                    </div>
                </div>
                <div className='flex flex-row justify-between px-4 border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold'>Total After Discount</div>
                    <div className=''>
                        $
                        {totalAfterDiscount?.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })}
                    </div>
                </div>
                <div className='flex flex-row justify-between px-4 h-10 mb-42 text-zentive-gray-medium'>
                    <div className='font-semibold'>Tax</div>
                    <div className=''>{displayCurrency(totalTaxAmount)}</div>
                </div>
                {totalTaxAmount > 0 && (
                    <div className='flex flex-col h-auto text-zentive-gray-medium mb-4'>
                        {productsTaxAmount > 0 && (
                            <div className='flex flex-row justify-between px-4'>
                                <p className='pl-4'>- Products {productsTaxPercentage}% </p>
                                <p>{displayCurrency(productsTaxAmount)}</p>
                            </div>
                        )}
                        {servicesTaxAmount > 0 && (
                            <div className='flex flex-row justify-between px-4'>
                                <p className='pl-4'>- Services {servicesTaxPercentage}% </p>
                                <p>{displayCurrency(servicesTaxAmount)}</p>
                            </div>
                        )}
                        {expensesTaxAmount > 0 && (
                            <div className='flex flex-row justify-between px-4'>
                                <p className='pl-4'>- Expenses {expensesTaxPercentage}% </p>
                                <p>{displayCurrency(expensesTaxAmount)}</p>
                            </div>
                        )}
                    </div>
                )}
                <div className='flex flex-row justify-between px-4 pt-4 border-t-[6px] border-zentive-gray-medium h-16 text-zentive-gray-medium'>
                    <div className='font-semibold'>Total </div>
                    <div className='text-zentive-green-dark font-semibold'>
                        {displayCurrency(total)}
                    </div>
                </div>
            </div>
            <div className='border border-[#EBEBEB] p-6 my-16 rounded-md'>
                <CardTitle className='text-lg mb-6'>Cost and Estimated Margin</CardTitle>
                <div className='flex flex-row justify-between px-4 border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold'>Product and Service Cost</div>
                    <div className=''>{displayCurrency(cost)}</div>
                </div>
                <div className='flex flex-row justify-between px-4  h-10 mb-4 text-zentive-gray-medium'>
                    <div className='flex flex-col'>
                        <div className='font-semibold'>Net Profit Margin</div>
                        <div className='font-semibold'>(Exclude of Tax)</div>
                    </div>
                    <div className='text-zentive-green-dark font-semibold'>
                        {`${displayCurrency(estimate)} (${displayWithDecimals(
                            estimatePercentage,
                            2,
                        )}%)`}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BillingAmount
