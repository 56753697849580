import BreadCrumbs from '@/components/BreadCrumbs'
import { SubscribersCount } from './SubscribersCount'
import SubscribersChart from './SubscribersChart'
import GeolocationWarningModal from '@/components/GeolocationWarningModal'
import { toast } from '@/hooks/useToast'
import { useState, useEffect } from 'react'
import { fetchTimezone } from '@/hooks/useGeocode'
import { useSetAtom } from 'jotai'
import { timezoneAtom } from '@/store/auth'

const AdminDashboard = () => {
    const [showGeolocationWarning, setShowGeolocationWarning] = useState(false)
    const setTimezone = useSetAtom(timezoneAtom)

    useEffect(() => {
        handleEnableLocation()
    }, [])

    const handleEnableLocation = () => {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                setShowGeolocationWarning(false)
                sessionStorage.setItem('geolocationWarningShown', 'true')
                const { latitude, longitude } = position.coords
                const timezone = await fetchTimezone({ lat: latitude, lng: longitude })
                setTimezone(timezone)
                if (sessionStorage.getItem('geolocationWarningShown') !== 'true') {
                    toast({
                        title: 'Location permission granted',
                        description: 'Location permission has been enabled',
                        variant: 'default',
                    })
                    sessionStorage.setItem('geolocationWarningShown', 'true')
                }
            },
            (error) => {
                if (
                    error.code === error.PERMISSION_DENIED &&
                    sessionStorage.getItem('geolocationWarningShown') !== 'true'
                ) {
                    setShowGeolocationWarning(true)
                    toast({
                        title: 'Location permission denied',
                        description: 'Please enable location permission to continue',
                        variant: 'destructive',
                    })
                }
            },
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            },
        )
    }

    // const checkAndRequestGeolocation = async () => {
    //     const permissionStatus = await navigator.permissions.query({ name: 'geolocation' })
    //     console.log('🚀 ~ checkAndRequestGeolocation ~ permissionStatus:', permissionStatus)

    //     if (permissionStatus.state === 'denied') {
    //         handleEnableLocation()
    //     } else {
    //         handleEnableLocation()
    //     }
    // }

    return (
        <div className='h-full'>
            <BreadCrumbs titleName='Dashboard' />
            <div className='h-full mt-10'>
                <h1 className='font-bold text-xl text-zentive-gray-medium'>
                    Hi, Welcome to Zentive!
                </h1>
            </div>

            <div className='flex flex-row gap-10'>
                <SubscribersCount />
                <SubscribersChart />
            </div>
            <GeolocationWarningModal
                open={showGeolocationWarning}
                setOpen={setShowGeolocationWarning}
            />
        </div>
    )
}

export default AdminDashboard
