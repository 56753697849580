import { useMutation, useQuery } from '@tanstack/react-query'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {
    ArrangementAndOptimizeType,
    OptimizeGroupsSchemaType,
    OwnerForemanProfileType,
    RouteOptimizeType,
    RouteParamType,
    RoutePolylineType,
} from '@/api/routing/schema'
import { AxiosError } from 'axios'
import { getCounts, getJobsOptimizePolyline, getOwnerForeman } from '@/api/routing'
import {
    isPolylineLoadingAtom,
    routeJobListAtom,
    routeOptimizeAtom,
    routePolylineAtom,
} from '@/store/route'
import OptimizeAlert from './OptimizeAlert'
import { useFormContext } from 'react-hook-form'

const JobRouteOptimization = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const [startingAddress, setStartingAddress] = useState<OwnerForemanProfileType | null>(null)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [jobRouteList, setRouteJobList] = useAtom(routeJobListAtom)
    const [isDisabled, setIsDisabled] = useState(true)
    const [isPolylineLoading, setIsPolylineLoading] = useAtom(isPolylineLoadingAtom)
    const setRouteOptimize = useSetAtom(routeOptimizeAtom)
    const setPolylineList = useSetAtom(routePolylineAtom)

    const isOptimizable = jobRouteList?.every(
        (r) => r.appointment.availableAnytime === true,
    ) as boolean

    const { setValue } = useFormContext<RouteParamType>()

    const { data } = useQuery({
        queryKey: ['profiles', user?.businessId],
        queryFn: () => getOwnerForeman(user?.businessId as string),
        enabled: !!user?.businessId,
    })

    const profiles = data || []

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen)
    }

    const { data: counts, refetch } = useQuery<unknown, AxiosError, ArrangementAndOptimizeType>({
        queryKey: ['counts'],
        queryFn: () => getCounts(user?.businessId as string),
    })

    const { mutate: getOptimizePolylineMu } = useMutation<
        RouteOptimizeType[],
        AxiosError,
        OptimizeGroupsSchemaType
    >({
        mutationFn: (data) => {
            setIsPolylineLoading(true)
            return getJobsOptimizePolyline(data)
        },
        onSuccess: (data: RouteOptimizeType[]) => {
            refetch()

            const profiles = data
                .filter((profile) => profile.isIncluded)
                ?.map((profile) => profile.profile)
                .filter((profile): profile is OwnerForemanProfileType => !!profile)

            const polylines: RoutePolylineType = {
                customers: profiles,
                encodedPolyline: data[0].encodedPolyline!,
            }

            setPolylineList(polylines)
            setValue('encodedPolyline', polylines.encodedPolyline)

            setRouteJobList((prevRouteJobList) => {
                if (!prevRouteJobList) {
                    return []
                }
                // Create a map to get the index of each profileId in polylines.customers
                const customerOrderMap = new Map(
                    polylines.customers.map((customer, index) => [customer.profileId, index]),
                )

                // Sort `prevRouteJobList` based on the index order from `customerOrderMap`
                return prevRouteJobList.sort((a, b) => {
                    const aIndex = customerOrderMap.get(a.quote.profileId) ?? 0
                    const bIndex = customerOrderMap.get(b.quote.profileId) ?? 0
                    return aIndex - bIndex
                })
            })

            setRouteOptimize(data)
            setIsPolylineLoading(false)
        },
        onError: () => {
            setIsPolylineLoading(false)
        },
    })

    useEffect(() => {
        if (jobRouteList) {
            setValue('jobGroup', jobRouteList)
        }
    }, [jobRouteList])

    useEffect(() => {
        if (counts?.optimizeCount! > 0 && startingAddress && !isPolylineLoading) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [counts, startingAddress, isPolylineLoading])

    return (
        <div className='flex items-start text-left justify-between mt-5 bg-white  '>
            <div className='flex w-[50%]'>
                <div className='relative w-[50%]'>
                    <div className='flex flex-col '>
                        <div
                            className='flex flex-col w-full hover:bg-zentive-gray-light cursor-pointer rounded-sm py-4 pl-5'
                            onClick={toggleDropdown}
                        >
                            <span className='text-zentive-gray-medium font-semibold'>
                                Choose Starting Address
                            </span>
                            <span className='text-lg text-zentive-gray-medium'>
                                {!startingAddress ? (
                                    <div className=''>
                                        <span className='text-base text-zentive-gray-medium'>
                                            --
                                        </span>
                                        <div className='flex flex-row'>
                                            <span className='text-base text-zentive-gray-medium '>
                                                --
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className=''>
                                        <span className='text-base '>
                                            {' '}
                                            {startingAddress.role?.roleName == 'CREW' ? (
                                                <span>
                                                    {startingAddress.firstName}{' '}
                                                    {startingAddress.lastName}
                                                </span>
                                            ) : (
                                                <span>My Address</span>
                                            )}
                                        </span>
                                        <div className='flex flex-row'>
                                            <span className='text-sm text-zentive-gray-medium '>
                                                {' '}
                                                {startingAddress.address.streetAddress},{' '}
                                                {startingAddress.address.city},{' '}
                                                {startingAddress.address.state},{' '}
                                                {startingAddress.address.zipCode}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </span>
                        </div>
                    </div>
                    {isDropdownOpen && (
                        <div className='mt-2 w-[80%] h-auto absolute rounded-md bg-white shadow-lg focus:outline-none z-50'>
                            <div className='bg-white rounded-md'>
                                {profiles?.map((item) => (
                                    <button
                                        key={item.profileId} // Add a unique key for each button
                                        className='w-full hover:bg-[#00000029] rounded-t-md text-left px-3 py-2'
                                        onClick={() => {
                                            setIsDropdownOpen(false)
                                            setStartingAddress(item)
                                        }}
                                    >
                                        <div className='flex flex-col text-base'>
                                            <span className='ml-2 font-semibold text-base'>
                                                {item.role?.roleName === 'CREW' ? (
                                                    <span>
                                                        {item.firstName} {item.lastName}
                                                    </span>
                                                ) : (
                                                    <span>My Address</span>
                                                )}
                                            </span>
                                            <span className='ml-2 text-base'>
                                                {item.role?.roleName === 'CREW' && (
                                                    <span>Foreman</span>
                                                )}
                                            </span>
                                            <div className='flex flex-row text-2xl justify-between'>
                                                <span className='text-sm text-zentive-gray-medium ml-2'>
                                                    <p className='text-sm text-zentive-gray-medium'>
                                                        {item.address.streetAddress},{' '}
                                                        {item.address.city},
                                                    </p>
                                                    <p className='text-sm text-zentive-gray-medium'>
                                                        {item.address.state}, {item.address.zipCode}
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='flex flex-col w-[35%] justify-end items-end ml-14 mt-[1.3rem] pr-8'>
                <OptimizeAlert
                    count={counts?.optimizeCount!}
                    getOptimizePolylineMu={getOptimizePolylineMu}
                    isDisabled={isDisabled || !isOptimizable}
                    startingAddress={startingAddress!}
                />
                <div className='text-right text-zentive-gray-medium text-sm mt-3'>
                    Total optimization used today: {2 - (counts?.optimizeCount ?? 0)} of 2
                </div>
            </div>
        </div>
    )
}

export default JobRouteOptimization
