import { useMutation, useQuery } from '@tanstack/react-query'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {
    ArrangementAndOptimizeType,
    OptimizeGroupsSchemaType,
    OwnerForemanProfileType,
    RouteOptimizeType,
    RouteParamType,
    RoutePolylineType,
} from '@/api/routing/schema'
import { AxiosError } from 'axios'
import { getCounts, getJobsOptimizePolyline, getOwnerForeman } from '@/api/routing'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import { convertImageToBase64 } from '@/utils/helper'
import {
    isPolylineLoadingAtom,
    routeJobListAtom,
    routeOptimizeAtom,
    routePolylineAtom,
} from '@/store/route'
import OptimizeAlert from './OptimizeAlert'
import { useFormContext } from 'react-hook-form'
import PrintModal from '@/components/PrintModal'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@/components/Table'
import { cn } from '@/utils/helper'
import { generateJobRoutePdf } from '@/utils/pdf'
import dayjs from 'dayjs'
import { COMPLETE_FORMAT_WITHOUT_TIME } from '@/constants'

const JobRouteOptimization = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const [startingAddress, setStartingAddress] = useState<OwnerForemanProfileType | null>(null)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [routeJobList, setRouteJobList] = useAtom(routeJobListAtom)
    const setIsPolylineLoading = useSetAtom(isPolylineLoadingAtom)
    const setRouteOptimize = useSetAtom(routeOptimizeAtom)
    const setPolylineList = useSetAtom(routePolylineAtom)

    const { setValue } = useFormContext<RouteParamType>()
    const ownerName = user?.business?.businessName as string
    const foreman = routeJobList?.[0]?.jobCrewMembers.find(
        (foreman) => routeJobList?.[0]?.foreman.crewMemberId === foreman.crewMemberId,
    )

    const handleDownloadPDF = async () => {
        try {
            const zentiveLogoUrl = await convertImageToBase64(zentiveLogo)

            generateJobRoutePdf(zentiveLogoUrl, routeJobList, ownerName)
        } catch (error) {
            console.error('Error converting image to Base64')
        }
    }

    const { data } = useQuery({
        queryKey: ['profiles', user?.businessId],
        queryFn: () => getOwnerForeman(user?.businessId as string),
        enabled: !!user?.businessId,
    })

    const profiles = data || []

    const toggleDropdown = () => {
        routeJobList && routeJobList.length > 0 && setIsDropdownOpen(!isDropdownOpen)
    }

    const { data: counts, refetch } = useQuery<unknown, AxiosError, ArrangementAndOptimizeType>({
        queryKey: ['counts'],
        queryFn: () => getCounts(user?.businessId as string),
    })

    const { mutate: getOptimizePolylineMu } = useMutation<
        RouteOptimizeType[],
        AxiosError,
        OptimizeGroupsSchemaType
    >({
        mutationFn: (data) => {
            setIsPolylineLoading(true)
            return getJobsOptimizePolyline(data)
        },
        onSuccess: (data: RouteOptimizeType[]) => {
            refetch()

            const profiles = data
                .filter((profile) => profile.isIncluded)
                ?.map((profile) => profile.profile)
                .filter((profile): profile is OwnerForemanProfileType => !!profile)

            const polylines: RoutePolylineType = {
                customers: profiles,
                encodedPolyline: data[0].encodedPolyline!,
            }

            setPolylineList(polylines)
            setValue('encodedPolyline', polylines.encodedPolyline)

            setRouteJobList((prevRouteJobList) => {
                if (!prevRouteJobList) {
                    return []
                }
                // Create a map to get the index of each profileId in polylines.customers
                const customerOrderMap = new Map(
                    polylines.customers.map((customer, index) => [customer.profileId, index]),
                )

                // Sort `prevRouteJobList` based on the index order from `customerOrderMap`
                return prevRouteJobList.sort((a, b) => {
                    const aIndex = customerOrderMap.get(a.quote.profileId) ?? 0
                    const bIndex = customerOrderMap.get(b.quote.profileId) ?? 0
                    return aIndex - bIndex
                })
            })

            setRouteOptimize(data)
            setIsPolylineLoading(false)
        },
        onError: () => {
            setIsPolylineLoading(false)
        },
    })

    return (
        <div className='flex items-start text-left justify-between mt-7 bg-white  '>
            <div className='flex w-[50%]'>
                <div className='relative w-[50%]'>
                    <div className='flex flex-col '>
                        <div
                            className='z-0 flex flex-col w-ful hover:bg-zentive-gray-light cursor-pointer rounded-sm py-4 pl-5'
                            onClick={toggleDropdown}
                        >
                            <span className='text-zentive-gray-medium font-semibold'>
                                Choose Starting Address
                            </span>
                            <span className='text-lg text-zentive-gray-medium'>
                                {!startingAddress ? (
                                    <div className=''>
                                        <span className='text-base text-zentive-gray-medium'>
                                            --
                                        </span>
                                        <div className='flex flex-row'>
                                            <span className='text-base text-zentive-gray-medium '>
                                                --
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className=''>
                                        <span className='text-base '>
                                            {' '}
                                            {startingAddress.role?.roleName == 'CREW' ? (
                                                <span>
                                                    {startingAddress.firstName}{' '}
                                                    {startingAddress.lastName}
                                                </span>
                                            ) : (
                                                <span>My Address</span>
                                            )}
                                        </span>
                                        <div className='flex flex-row'>
                                            <span className='text-sm text-zentive-gray-medium '>
                                                {' '}
                                                {startingAddress.address.streetAddress},{' '}
                                                {startingAddress.address.city},{' '}
                                                {startingAddress.address.state},{' '}
                                                {startingAddress.address.zipCode}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </span>
                        </div>
                    </div>
                    {isDropdownOpen && (
                        <div className='mt-2 w-[80%] h-auto absolute rounded-md bg-white shadow-lg focus:outline-none z-50'>
                            <div className='bg-white rounded-md'>
                                {profiles?.map((item) => (
                                    <button
                                        key={item.profileId} // Add a unique key for each button
                                        className='w-full hover:bg-[#00000029] rounded-t-md text-left px-3 py-2'
                                        onClick={() => {
                                            setIsDropdownOpen(false)
                                            setStartingAddress(item)
                                        }}
                                    >
                                        <div className='flex flex-col text-base'>
                                            <span className='ml-2 font-semibold text-base'>
                                                {item.role?.roleName === 'CREW' ? (
                                                    <span>
                                                        {item.firstName} {item.lastName}
                                                    </span>
                                                ) : (
                                                    <span>My Address</span>
                                                )}
                                            </span>
                                            <span className='ml-2 text-base'>
                                                {item.role?.roleName === 'CREW' && (
                                                    <span>Foreman</span>
                                                )}
                                            </span>
                                            <div className='flex flex-row text-2xl justify-between'>
                                                <span className='text-sm text-zentive-gray-medium ml-2'>
                                                    <p className='text-sm text-zentive-gray-medium'>
                                                        {item.address.streetAddress},{' '}
                                                        {item.address.city},
                                                    </p>
                                                    <p className='text-sm text-zentive-gray-medium'>
                                                        {item.address.state}, {item.address.zipCode}
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='flex flex-col w-[35%] ml-14 mt-[1.3rem] pr-8'>
                <div className='flex flex-row gap-x-2'>
                    <OptimizeAlert
                        count={counts?.optimizeCount!}
                        getOptimizePolylineMu={getOptimizePolylineMu}
                        startingAddress={startingAddress!}
                    />
                    <PrintModal
                        disabled={!routeJobList || routeJobList.length == 0}
                        triggerTitle='Print Route Sheet'
                        title='Route Sheet'
                        triggerClassName={cn(
                            !routeJobList || routeJobList.length == 0
                                ? 'border-zentive-green-dark text-zentive-green-dark bg-zentive-gray-light'
                                : 'border-zentive-green-dark text-zentive-green-dark bg-background shadow-sm hover:bg-accent',
                            'whitespace-nowrap px-8 border font-semibold rounded-md',
                        )}
                        handleDownloadPDF={handleDownloadPDF}
                    >
                        <div className='flex justify-center py-8 text-2xl font-bold'>
                            {ownerName}
                        </div>
                        <div className='bg-zentive-green-dark text-white p-2'>
                            {`Route Sheet For: ${routeJobList?.[0]?.crew?.description}`}
                        </div>
                        <div className='flex justify-center p-8'>
                            <Table>
                                <TableRow className='whitespace-nowrap bg-zentive-green-light border'>
                                    <TableHead className='font-semibold  text-black text-base border-r-2'>
                                        Date Printed
                                    </TableHead>
                                    <TableHead className='font-semibold text-black border-r-2 text-base'>
                                        Total Man Hours
                                    </TableHead>
                                    <TableHead className='font-semibold  text-black text-base border-r-2'>
                                        Total Area (Yards)
                                    </TableHead>
                                    <TableHead className='font-semibold  text-black text-base'>
                                        Foreman
                                    </TableHead>
                                </TableRow>
                                <TableBody>
                                    <TableRow className='border'>
                                        <TableCell className='flex-row items-start border text-start text-zentive-black max-w-[15rem] text-base'>
                                            <p>{dayjs().format(COMPLETE_FORMAT_WITHOUT_TIME)}</p>
                                        </TableCell>
                                        <TableCell className='flex-row items-start border text-start text-zentive-black max-w-[15rem] text-base'>
                                            <p>--</p>
                                        </TableCell>
                                        <TableCell className='flex-row items-start border text-start text-zentive-black max-w-[15rem] text-base'>
                                            <p>--</p>
                                        </TableCell>
                                        <TableCell className='flex-row items-start border text-start text-zentive-black max-w-[15rem] text-base'>
                                            <p>
                                                {foreman?.memberProfile?.firstName +
                                                    ' ' +
                                                    foreman?.memberProfile?.lastName}
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                        <p className='px-10 text-zentive-green-dark font-semibold'>Crew</p>
                        <div className='flex justify-center px-44'>
                            <Table>
                                <TableRow className='whitespace-nowrap bg-zentive-green-light '>
                                    <TableHead className='font-semibold border text-black text-base border-r-2'>
                                        Name
                                    </TableHead>
                                    <TableHead className='font-semibold border text-black text-base border-r-2'>
                                        Email Address
                                    </TableHead>
                                </TableRow>
                                <TableBody>
                                    {routeJobList?.[0]?.jobCrewMembers?.map((crew, index) => (
                                        <TableRow key={index}>
                                            <TableCell className='flex-row items-start border text-start text-zentive-black max-w-[15rem] text-base'>
                                                <p>{`${
                                                    crew?.memberProfile?.firstName +
                                                    ' ' +
                                                    crew?.memberProfile?.lastName
                                                } ${
                                                    crew.crewMemberId !== foreman?.crewMemberId
                                                        ? '(Foreman)'
                                                        : ''
                                                }`}</p>
                                            </TableCell>
                                            <TableCell className='flex-row items-start border text-start text-zentive-black max-w-[15rem] text-base'>
                                                <p>{crew?.memberProfile?.email}</p>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                        <p className='px-10 text-zentive-green-dark font-semibold'>
                            Stops in Order
                        </p>

                        {routeJobList?.map((job, index) => (
                            <div className='flex flex-col justify-center p-8' key={index}>
                                <div className='flex justify-center bg-zentive-green-dark text-white p-2'>
                                    {`${index + 1}. ${job?.quote?.profile?.firstName} ${job?.quote
                                        ?.profile?.lastName}`}
                                </div>
                                <Table>
                                    <TableRow className='whitespace-nowrap bg-zentive-green-light border'>
                                        <TableHead className='font-semibold  text-black text-base border-r-2'>
                                            Job
                                        </TableHead>
                                        <TableHead className='font-semibold text-black border-r-2 text-base'>
                                            Address
                                        </TableHead>
                                        <TableHead className='font-semibold  text-black text-base border-r-2'>
                                            Email Address
                                        </TableHead>
                                        <TableHead className='font-semibold  text-black text-base'>
                                            Phone Number
                                        </TableHead>
                                    </TableRow>
                                    <TableBody>
                                        <TableRow className='border'>
                                            <TableCell className='flex-row items-start border text-start text-zentive-black max-w-[15rem] text-base'>
                                                <p>{job?.quote?.productServiceGroup[0]?.name}</p>
                                            </TableCell>
                                            <TableCell className='flex-row items-start border text-start text-zentive-black max-w-[15rem] text-base'>
                                                <p>{`${job?.address?.streetAddress + ','} ${
                                                    job?.address?.city + ','
                                                } ${job?.address?.state + ','} ${job?.address
                                                    ?.zipCode}`}</p>
                                            </TableCell>
                                            <TableCell className='flex-row items-start border text-start text-zentive-black max-w-[15rem] text-base'>
                                                <p>{job?.quote?.profile?.email}</p>
                                            </TableCell>
                                            <TableCell className='flex-row items-start border text-start text-zentive-black max-w-[15rem] text-base'>
                                                <p>{job?.quote?.profile?.phoneNumber}</p>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        ))}
                    </PrintModal>
                </div>
                <div className='text-zentive-gray-medium text-sm mt-3'>
                    Total optimization used today: {2 - (counts?.optimizeCount ?? 0)} of 2
                </div>
            </div>
        </div>
    )
}

export default JobRouteOptimization
